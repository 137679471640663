import PropTypes from 'prop-types';
import { equals } from 'ramda';
import React, { useCallback, useRef, memo } from 'react';
import { useMousePositionAsTrigger, useLayer } from 'react-laag';
import { useSelector } from 'react-redux';
import { useUnmount } from 'react-use';

import {
  selectElementScreenshot as selectStepElementScreenshot,
  selectCurrentStepId,
} from '~/modules/test/test.selectors';
import { selectElementScreenshot as selectStepRunElementScreenshot } from '~/modules/testRun/testRun.selectors';
import analytics, { TRACK_EVENT_TYPE } from '~/services/analytics';
import { getScreenshotUrl } from '~/utils/misc';
import useStepContext from '~/views/TestDetails/TestDetails.context';

import TextCell from '../TextCell';

import { HIDE_PREVIEW_DELAY_MS } from './ImageCell.constants';
import { Container, TextContainer, Image, PreviewImage } from './ImageCell.styled';

const areEqualProps = (prevProps, nextProps) => equals(prevProps.value, nextProps.value);

const ImageCell = memo(({ className, value }) => {
  const { runResultEnabled } = useStepContext() || {};
  const selectElementScreenshot = runResultEnabled
    ? selectStepRunElementScreenshot
    : selectStepElementScreenshot;
  const sourceUrl = useSelector(selectElementScreenshot(value.id));
  const currentStepId = useSelector(selectCurrentStepId);
  const isRelatedStepActive = currentStepId === value.id;
  const imageUrl = getScreenshotUrl(sourceUrl);
  const containerRef = useRef();
  const mouseLeaveTimeout = useRef();

  const { hasMousePosition, handleMouseEvent, resetMousePosition } = useMousePositionAsTrigger();

  const isOpen = hasMousePosition;
  const { layerProps, layerSide, renderLayer, triggerBounds } = useLayer({
    isOpen,
    placement: 'top-center',
    onOutsideClick: resetMousePosition,
    overflowContainer: true,
    auto: true,
    onDisappear: () => resetMousePosition(),
    trigger: {
      getBounds() {
        return containerRef.current?.getBoundingClientRect();
      },
      getParent() {
        return containerRef.current?.parentElement;
      },
    },
  });

  const handleClick = useCallback(
    (event, callback) => {
      if (isRelatedStepActive) {
        event.stopPropagation();
      }
      callback(event);
    },
    [isRelatedStepActive],
  );

  const handlePreviewMouseLeave = useCallback((event) => {
    event.persist();
    mouseLeaveTimeout.current = setTimeout(() => {
      resetMousePosition(event);
    }, HIDE_PREVIEW_DELAY_MS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePreviewMouseEnter = useCallback(() => {
    clearTimeout(mouseLeaveTimeout.current);
  }, []);

  useUnmount(() => {
    clearTimeout(mouseLeaveTimeout.current);
  });

  // eslint-disable-next-line react/prop-types
  const renderPreview = () =>
    renderLayer(
      <PreviewImage
        loading="lazy"
        onMouseEnter={handlePreviewMouseEnter}
        onMouseLeave={handlePreviewMouseLeave}
        onMouseDown={resetMousePosition}
        data-testid="ImageCell.Preview"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...layerProps}
        src={imageUrl}
        side={layerSide}
        // eslint-disable-next-line react/prop-types
        initialHeight={triggerBounds.height}
        // eslint-disable-next-line react/prop-types
        initialWidth={triggerBounds.width}
      />,
    );

  const trackScreenshotClick = useCallback(() => {
    analytics.trackEvent(TRACK_EVENT_TYPE.SCREENSHOT_ELEMENT_CLICKED);
  }, []);

  const handleImageClick = useCallback(
    (event) => {
      trackScreenshotClick();
      handleClick(event, handleMouseEvent);
    },
    [handleClick, handleMouseEvent, trackScreenshotClick],
  );

  if (!imageUrl) {
    return (
      <TextContainer className={className}>
        <TextCell />
      </TextContainer>
    );
  }

  return (
    <Container
      ref={containerRef}
      className={className}
      onClick={handleImageClick}
      data-testid="ImageCell"
    >
      <Image loading="lazy" src={imageUrl} alt={value.alt || value.type} />
      {isOpen && renderPreview()}
    </Container>
  );
}, areEqualProps);

ImageCell.displayName = 'ImageCell';

ImageCell.defaultProps = {
  className: null,
};

ImageCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    alt: PropTypes.string,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
};

export default ImageCell;
