export const DRAG_DROP_ITEM_TYPE = {
  GROUP: 'GROUP',
  STEP: 'STEP',
};

export const DRAG_END_REASON = {
  DROP: 'DROP',
};

export const ROW_ID_ATTR_NAME = 'data-rowid';

export const ROW_HEIGHT = 52;

export const ROWS_TO_RENDER_OVER_VIEWPORT = 4;
