import PropTypes from 'prop-types';
import React from 'react';

import { useTableContext } from '../../Table.context';

import { Container, Checkbox } from './SelectionCell.styled';

const SelectionCell = ({ className, style, readOnly, disabled, ...rest }) => {
  const { lastFocusedRowId, setLastFocusedRowId } = useTableContext();

  const onClick = (e) => {
    const { rows, rowsById, cell } = rest;

    if (!cell) {
      setLastFocusedRowId(0);
      return;
    }

    const { row } = cell;
    const focusedRowId = Number(row.id);

    if (e.shiftKey && lastFocusedRowId !== null) {
      const isCellSelected = rowsById[row.id].isSelected;
      const rowsToToggle = getRowRange(rows, focusedRowId, lastFocusedRowId);
      rowsToToggle.forEach((_row) => _row.toggleRowSelected(!isCellSelected));
    }

    setLastFocusedRowId(focusedRowId);
  };

  return (
    <Container>
      <Checkbox
        small
        className={className}
        data-testid="SelectionCell"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        disabled={disabled || readOnly}
        onClick={onClick}
      />
    </Container>
  );
};

const getRowRange = (rows, currentId, selectedId) => {
  const rangeStart = selectedId > currentId ? currentId : selectedId;
  const rangeEnd = rangeStart === currentId ? selectedId : currentId;
  return rows.slice(rangeStart, rangeEnd + 1);
};

SelectionCell.defaultProps = {
  className: null,
  indeterminate: false,
  disabled: false,
  readOnly: false,
  style: null,
};

SelectionCell.propTypes = {
  className: PropTypes.string,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  style: PropTypes.shape({}),
};

export default SelectionCell;
