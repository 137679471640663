import type { ListChildComponentProps } from 'react-window';

import { RowCell, TableLinkRow, TableRow as RegularTableRow } from './Table.styled';

interface RowProps extends Partial<ListChildComponentProps> {
  index: number;
}

export const TableRow = ({ data: rowData, index, style }: RowProps) => {
  const row = rowData.items[index];
  rowData.prepareRow?.(row);

  const { onDelete, ...customRowProps } = rowData.getRowProps?.(row.original, index) ?? {};
  const rowProps = row.getRowProps({
    style,
    ...(customRowProps || {}),
    'data-clickable': !!customRowProps?.onClick,
  });

  const RowBase = rowData.Component || (customRowProps.to ? TableLinkRow : RegularTableRow);

  return (
    <RowBase {...rowProps} aria-rowindex={index}>
      {row.cells.map((cell, cellIndex) => (
        <RowCell
          {...cell.getCellProps({
            style: {
              width: cell.column.width ?? 'auto',
              maxWidth: `${cell.column.maxWidth || cell.column.width}px`,
            },
            'aria-colindex': cellIndex,
          })}
          key={cell.column.id}
        >
          {cell.render('Cell', { readOnly: rowData.readOnly, onDelete })}
        </RowCell>
      ))}
    </RowBase>
  );
};
