import './dayjsExtensions';

import dayjs from 'dayjs';

export const getRelativeTime = (date) => {
  const day = dayjs(date);
  const today = dayjs();

  if (today.diff(day, 'minutes', true) < 5) {
    return 'just now';
  }
  return day.fromNow();
};
