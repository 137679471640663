import { COLOR, hexToRgba } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import RcPagination from 'rc-pagination';
import { Link as LinkBase } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(RcPagination)`
  display: flex;
  padding: 0 2px;
  margin: 0;

  .rc-pagination-item {
    position: relative;
    font-size: 13px;
    display: flex;
  }

  .rc-pagination-jump-next,
  .rc-pagination-jump-prev {
    font-size: 15px;
    letter-spacing: 1px;
    pointer-events: none;

    &:after {
      content: '...' !important;
      color: currentColor;
      letter-spacing: 1px;
    }
  }

  .rc-pagination-prev,
  .rc-pagination-next {
    position: relative;
    display: flex;
    font-size: 20px;

    a:after {
      top: -2px;
      position: relative;
    }
  }

  .rc-pagination-prev a:after {
    content: '‹';
    left: -1px;
  }

  .rc-pagination-next a:after {
    content: '›';
  }

  .rc-pagination-item,
  .rc-pagination-prev,
  .rc-pagination-next,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    background: ${COLOR.TRANSPARENT};
    border: 2px solid;
    border-color: ${COLOR.WHITE};
    color: ${COLOR.DARK_GRAY};
    font-weight: ${FONT_WEIGHT.MEDIUM};
    padding: 0 0 2px;
    width: 24px;
    height: 24px;
    margin: 2px 4px;
    min-width: 0;
    outline: none;
    border-radius: 4px;
    opacity: 0.56;
    cursor: pointer;
    list-style: none;
    line-height: 17px;
    text-align: center;

    a {
      color: inherit !important;
    }

    &:hover {
      opacity: 1;
      color: ${COLOR.BLACK};
      border-color: ${hexToRgba(COLOR.DARK_GRAY, 0.26)};
    }
  }

  .rc-pagination-item.rc-pagination-item-active {
    color: ${COLOR.PRIMARY_LIGHT};
    border-color: currentColor;
  }
`;

export const Link = styled(LinkBase)`
  flex: 1;

  &:hover {
    text-decoration: none;
  }
`;
