import { omit } from 'ramda';
import { useCallback, useMemo, useRef } from 'react';

import useQueryString from '~/hooks/useQueryString';
import useUpdateQueryString from '~/hooks/useUpdateQueryString';
import localStorage from '~/services/localStorage';

const useRecentSort = ({ cacheName = 'cached', defaultConfig = {}, columns }) => {
  const queryParams = useRef({});
  queryParams.current = useQueryString();
  const cachedSortByKey = `table.${cacheName}.sortBy`;
  const updateQueryParams = useUpdateQueryString();

  const recentSort = useMemo(
    () => localStorage.getUserItem(cachedSortByKey) ?? {},
    [cachedSortByKey],
  );

  const initialSortConfig = useMemo(
    () => ({
      sortBy: queryParams.current.sortBy ?? recentSort.sortBy ?? defaultConfig.sortBy,
      desc: Boolean(queryParams.current.desc ?? recentSort.desc ?? defaultConfig.desc),
    }),
    [recentSort.sortBy, recentSort.desc, defaultConfig.sortBy, defaultConfig.desc],
  );

  const availableOptions = useMemo(
    () => (columns ? columns.filter((row) => !row.disableSortBy).map(({ id }) => id) : undefined),
    [columns],
  );

  const updateRecentSort = useCallback(
    (sortSettings) => {
      if (sortSettings.sortBy === recentSort.sortBy && sortSettings.desc === recentSort.desc) {
        return;
      }

      if (
        !availableOptions ||
        availableOptions.includes(sortSettings.sortBy) ||
        defaultConfig.sortBy === sortSettings.sortBy
      ) {
        updateQueryParams(sortSettings);
        localStorage.setUserItem(cachedSortByKey, omit(['query'], sortSettings));
      }
    },
    [
      recentSort.sortBy,
      recentSort.desc,
      availableOptions,
      defaultConfig.sortBy,
      updateQueryParams,
      cachedSortByKey,
    ],
  );

  return {
    ...initialSortConfig,
    updateRecentSort,
  };
};

export default useRecentSort;
