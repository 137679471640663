import { UnescapedValue } from '@bugbug/core/theme/typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AnswerPromptParamsContainer, TextBlock } from './ParamsCell.styled';

const AnswerPromptParams = React.memo(({ className, value = '' }) => {
  const { t } = useTranslation();
  const isAccept = value && value !== 'false';
  const hasCustomValue = isAccept && value !== 'true';

  let label = isAccept
    ? t('answerPromptParams.accept', 'Click "OK"')
    : t('answerPromptParams.cancel', 'Click "Cancel"');
  if (hasCustomValue) {
    label = t('answerPromptParams.acceptWithValue', 'Answer');
  }

  return (
    <AnswerPromptParamsContainer className={className}>
      {hasCustomValue ? (
        <TextBlock>
          <UnescapedValue>{value}</UnescapedValue>
        </TextBlock>
      ) : (
        <span>{label}</span>
      )}
    </AnswerPromptParamsContainer>
  );
});

AnswerPromptParams.displayName = 'AnswerPromptParams';

export default AnswerPromptParams;
