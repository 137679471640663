import styled from 'styled-components';

import EditableTextBase from '~/components/EditableText';

export const EditableText = styled(EditableTextBase)`
  padding: 0;
  margin-left: 0px;
  background: none;
  border-color: transparent;
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: none;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  width: 70%;
  height: 30px;
`;
