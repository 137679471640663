import { renderWhenTrueOtherwise } from '@bugbug/core/utils/rendering';
import PropTypes from 'prop-types';
import { compose, head, isEmpty, toUpper } from 'ramda';
import React from 'react';

import { Container, Image, Text } from './Avatar.styled';

const Avatar = ({ className, name, imgSrc }) => {
  const imgAlt = [name, 'avatar'].filter((v) => !!v).join(' ');
  const canRenderImage = imgSrc && !isEmpty(imgSrc);

  const renderContent = renderWhenTrueOtherwise(
    () => <Image alt={imgAlt} src={imgSrc} />,
    () => <Text>{compose(toUpper, head)(name)}</Text>,
  );

  return (
    <Container className={className} data-testid="Avatar">
      {renderContent(canRenderImage)}
    </Container>
  );
};

Avatar.defaultProps = {
  className: null,
  imgSrc: null,
  name: '',
};

Avatar.propTypes = {
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  name: PropTypes.string,
};

export default Avatar;
