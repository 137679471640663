import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUserData } from '~/modules/user/user.selectors';

import { Avatar, Container, Text } from './UserCell.styled';

const UserCell = ({ className, value, row }) => {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUserData);
  const { isConfirmed, firstName } = row.original;

  const renderCurrentUserLabel = renderWhenTrue(() => ` (${t('userCell.you', 'You')})`);
  const renderInvitedUserLabel = renderWhenTrue(() => ` (${t('userCell.invited', 'invited')})`);

  return (
    <Container className={className} data-testid="UserCell">
      <Avatar name={firstName || value} />
      <Text>
        {value}
        {renderCurrentUserLabel(currentUser.email === value)}
        {renderInvitedUserLabel(!isConfirmed)}
      </Text>
    </Container>
  );
};

UserCell.defaultProps = {
  className: null,
  value: null,
};

UserCell.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      firstName: PropTypes.string,
      isConfirmed: PropTypes.bool,
    }).isRequired,
  }).isRequired,
};

export default UserCell;
