import { UnescapedValue } from '@bugbug/core/theme/typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ASSERTION_TYPE_PARAMS,
  STEP_TYPE_PARAMS_LABEL,
  ASSERTION_PROPERTY,
  ASSERTION_TYPE,
} from '~/constants/step';

import { AssertParamsContainer, TextBlock } from './ParamsCell.styled';

const AssertParams = React.memo(({ className, type = '', property = '', expectedValue = '' }) => {
  const { t } = useTranslation();
  const shouldHideOperator =
    ASSERTION_PROPERTY[property]?.isTrueFalse || ASSERTION_PROPERTY[property]?.hiddenOperator;

  let expectedValueLabel = expectedValue || t('assertParams.empty', '(empty)');

  if (type === ASSERTION_TYPE.ANY) {
    expectedValueLabel = `(${ASSERTION_TYPE_PARAMS[type].label})`;
  }

  return (
    <AssertParamsContainer className={className}>
      <span>
        {STEP_TYPE_PARAMS_LABEL[property]}{' '}
        {shouldHideOperator ? '' : ASSERTION_TYPE_PARAMS[type]?.label}
      </span>
      {!ASSERTION_PROPERTY[property]?.isTrueFalse && (
        <TextBlock>
          <UnescapedValue>{expectedValueLabel}</UnescapedValue>
        </TextBlock>
      )}
    </AssertParamsContainer>
  );
});

AssertParams.displayName = 'AssertParams';

export default AssertParams;
