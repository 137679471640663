import { ChevronRightIcon, COLOR } from '@bugbug/core/theme';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Expander = styled(ChevronRightIcon)`
  color: ${COLOR.GRAY_16};
  transition: all 250ms ease-in-out !important;
`;

export const ExpanderContainer = styled.span`
  cursor: pointer;

  ${styleWhenTrue(
    prop('active'),
    css`
      ${Expander} {
        transform: rotate(90deg);
      }
    `,
  )}
`;
