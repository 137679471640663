import React, { useContext } from 'react';

export const StepContext = React.createContext();

const useStepContext = () => {
  const stepContext = useContext(StepContext);
  return stepContext;
};

export default useStepContext;
