import Icon from '@bugbug/core/components/Icon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { MouseEventHandler } from 'react';

import { getFileNameFromUrl } from '~/utils/misc';

import { Container, Name, Actions, IconButton, CloseIcon } from './FilePreview.styled';

interface FilePreviewProps {
  className?: string;
  name: string;
  url?: string;
  onRemove?: MouseEventHandler<HTMLButtonElement>;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  hideDownload?: boolean;
}

const FilePreview = ({
  className,
  name,
  url,
  onRemove,
  onCancel,
  hideDownload,
}: FilePreviewProps) => {
  const { t } = useTranslation();

  const fileName = name ? (name || getFileNameFromUrl(url)).replace(/\?/g, '') : '';

  const handleDownload = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    if (!url) return;
    const link = document.createElement('a');
    link.setAttribute('download', fileName);
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, [fileName, url]);

  const handleCloseClick = onRemove || onCancel;

  return (
    <Container
      className={className}
      data-testid="FilePreview"
      aria-label={t('filePreview.ariaLabel', 'Preview of {{ name }}', {
        name: fileName,
      })}
    >
      <Name>{fileName}</Name>
      <Actions>
        {!hideDownload && (
          <IconButton
            onClick={handleDownload}
            aria-label={t('filePreview.download', 'Download file')}
          >
            <Icon name="download" />
          </IconButton>
        )}
        {handleCloseClick && (
          <IconButton
            onClick={handleCloseClick}
            aria-label={t('filePreview.remove', 'Remove file')}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Actions>
    </Container>
  );
};

export default FilePreview;
