import Icon from '@bugbug/core/components/Icon';
import IconButtonBase from '@bugbug/core/components/IconButton';
import { COLOR } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
`;

export const Image = styled.img`
  border: 2px solid #eaeaea;
  border-radius: 4px;
  max-width: 100%;
  max-height: 80px;
  margin-bottom: 8px;
`;

export const Name = styled.span`
  margin-right: 8px;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseIcon = styled(Icon).attrs(() => ({
  name: 'close',
}))`
  transform: scale(0.7);
`;

export const IconButton = styled(IconButtonBase)`
  && {
    margin-left: 0;
  }
  color: #788088;

  &:hover {
    color: ${COLOR.DARK_GRAY};
  }

  svg {
    width: 16px;
    height: 16px;
    fill: none;
  }
`;
