import { UnescapedValue } from '@bugbug/core/theme/typography';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';

import { SelectParamsContainer, TextBlock } from './ParamsCell.styled';

const SelectParams = React.memo(({ className, type = '', value = '' }) => (
  <SelectParamsContainer className={className}>
    <Trans i18nKey="selectParams.content">
      <span>by {{ type }}</span>
      <TextBlock>
        <UnescapedValue>{{ value }}</UnescapedValue>
      </TextBlock>
    </Trans>
  </SelectParamsContainer>
));

SelectParams.displayName = 'SelectParams';

SelectParams.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default SelectParams;
