import { COLOR } from '@bugbug/core/theme/colors';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { path } from 'ramda';
import styled, { css } from 'styled-components';

import { TableLinkRow, TableRow } from '../../Table.styled';

const highlightedStyle = css`
  background-color: ${COLOR.SECONDARY_LIGHT} !important;
`;

const rowStyles = css`
  &:hover,
  &[data-selected='true'] {
    background-color: ${COLOR.PRIMARY_5};
  }

  ${styleWhenTrue(path(['theme', 'highlighted']), highlightedStyle)};

  ${styleWhenTrue(
    path(['theme', 'clickable']),
    css`
      cursor: pointer;
    `,
  )};
`;

export const LinkRow = styled(TableLinkRow)`
  ${rowStyles}
`;

export const Row = styled(TableRow)`
  ${rowStyles}
`;

export const DataRowWrapper = styled.div`
  position: relative;
`;
