import { KEY_BINDINGS } from '@bugbug/core/constants/keyBindings';
import { useFormik } from 'formik';
import memoize from 'lodash.memoize';
import PropTypes from 'prop-types';
import React, { useCallback, memo, useRef, useImperativeHandle, forwardRef } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useUpdateEffect } from 'react-use';
import * as Yup from 'yup';

import * as validators from '~/utils/validators';

import { Container, Input, ErrorMessage } from './EditableText.styled';

const getEditableTextSchema = memoize((maxChars) => {
  const baseValueValidator = validators.nameValidator;
  return Yup.object().shape({
    value: maxChars
      ? baseValueValidator.max(maxChars, validators.VALIDATION_MESSAGE.MAX_LENGTH)
      : baseValueValidator,
  });
});

const EditableText = memo(
  forwardRef((props, ref) => {
    const { className, value, onChange, onBlur, readOnly, disabled, max, hidden, ...inputProps } =
      props;
    const inputRef = useRef();

    const submitValueChange = useCallback(
      async (form, formik) => {
        const { value: isValueInvalid } = await formik.validateForm();
        if (!isValueInvalid && value !== form.value) {
          onChange(form.value);
        }
        if (!isValueInvalid && onBlur) {
          onBlur();
        }
      },
      [onChange, value, onBlur],
    );

    const formik = useFormik({
      initialValues: { value },
      validationSchema: getEditableTextSchema(max),
      onSubmit: submitValueChange,
    });

    useUpdateEffect(() => {
      formik.setFieldValue('value', value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleBlur = useCallback(
      (event) => {
        formik.handleBlur(event);
        formik.submitForm();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );

    const handleKeyEvent = useCallback(() => inputRef.current.blur(), []);

    useImperativeHandle(
      ref,
      () => ({
        setError: (error) => formik.setFieldError('value', error),
        input: inputRef.current,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [formik.setFieldError],
    );

    return (
      <Container onSubmit={formik.handleSubmit} data-testid="EditableText" hidden={hidden}>
        <KeyboardEventHandler
          handleKeys={[KEY_BINDINGS.ESC, KEY_BINDINGS.ENTER]}
          onKeyEvent={handleKeyEvent}
        >
          <Input
            {...inputProps}
            ref={inputRef}
            type="text"
            name="value"
            onChange={formik.handleChange}
            className={className}
            onBlur={handleBlur}
            value={formik.values.value}
            error={!formik.isValid}
            readOnly={readOnly}
            disabled={disabled}
          />
        </KeyboardEventHandler>
        {!formik.isValid && <ErrorMessage>{formik.errors.value}</ErrorMessage>}
      </Container>
    );
  }),
);

EditableText.displayName = 'EditableText';

EditableText.defaultProps = {
  className: null,
  value: '',
  readOnly: false,
  disabled: false,
  max: null,
  onChange: Function.prototype,
};

EditableText.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  max: PropTypes.number,
};

export default EditableText;
