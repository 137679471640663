import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

import { ExpanderContainer, Expander } from './ExpanderButton.styled';

const ExpanderButton = memo(({ className, active, onClick, ...buttonProps }) => {
  const handleClick = useCallback(
    (event) => {
      if (onClick) {
        event.stopPropagation();
        onClick(event);
      }
    },
    [onClick],
  );

  return (
    <ExpanderContainer
      className={className}
      active={active}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      onClick={handleClick}
      data-testid="ExpanderButton"
    >
      <Expander />
    </ExpanderContainer>
  );
});

ExpanderButton.displayName = 'ExpanderButton';

ExpanderButton.defaultProps = {
  className: null,
  active: false,
  onClick: null,
};

ExpanderButton.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ExpanderButton;
