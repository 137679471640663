import { TextBlock } from '@bugbug/core/theme/typography';
import PropTypes from 'prop-types';
import { isNil, is } from 'ramda';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isValidUrl } from './TextCell.helpers';
import { Container, ExternalLink, LinkIcon } from './TextCell.styled';

function areEqual(prevProps, nextProps) {
  return prevProps.value === nextProps.value;
}

const TextCell = React.memo(({ className, value = null, column = {}, parsedUrls = false }) => {
  const { t } = useTranslation();
  const { defaultValue = t('default.table.cell.value', '-') } = column;
  const valueToDisplay = useMemo(() => {
    if (isNil(value)) {
      return defaultValue;
    }

    if (is(String, value)) {
      return value.replace(/ /g, '\u00a0');
    }

    return value;
  }, [value, defaultValue]);

  const isUrl = parsedUrls && isValidUrl(valueToDisplay);

  return (
    <Container className={className} data-testid="TextCell" isUrl={isUrl}>
      {isUrl ? (
        <>
          <TextBlock>{valueToDisplay}</TextBlock>
          <ExternalLink href={valueToDisplay} target="_blank">
            <LinkIcon />
          </ExternalLink>
        </>
      ) : (
        valueToDisplay
      )}
    </Container>
  );
}, areEqual);

TextCell.displayName = 'TextCell';

TextCell.propTypes = {
  className: PropTypes.string,
  block: PropTypes.bool,
  parsedUrls: PropTypes.bool,
  column: PropTypes.shape({
    defaultValue: PropTypes.string,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TextCell;
