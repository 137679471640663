import { createContext, useContext } from 'react';

import type { ReactNode } from 'react';

type TableContextValue = {
  lastFocusedRowId: number | null;
  setLastFocusedRowId: (value: number | null) => void;
} | null;

const TableContext = createContext<TableContextValue>(null);

export const TableProvider = ({
  value,
  children,
}: {
  value: TableContextValue;
  children: ReactNode;
}) => <TableContext.Provider value={value}>{children}</TableContext.Provider>;

export const useTableContext = () => {
  const context = useContext(TableContext);

  if (!context) {
    throw new Error('useTableContext must be used within a TableProvider');
  }

  return context;
};
