/* eslint-disable react/prop-types, react/display-name, react/jsx-props-no-spreading, react/jsx-key */
import EmptyState from '@bugbug/core/components/EmptyState';
import { KEY_CODES_BINDINGS } from '@bugbug/core/constants/keyBindings';
import { renderWhenTrue } from '@bugbug/core/utils/rendering';
import { path } from 'ramda';
import {
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
  useMemo,
  useCallback,
  memo,
  useState,
} from 'react';
import { useTable, useFlexLayout, useSortBy, useRowSelect } from 'react-table';
import { useFirstMountState } from 'react-use';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import type { Data, TableBodyStyledProps } from './Table.types';
import type React from 'react';
import type { Column, SortingRule, Row } from 'react-table';
import type { Size } from 'react-virtualized-auto-sizer';

import ColumnHeader from './components/ColumnHeader';
import { TableRow } from './Row';
import { ROW_HEIGHT, ROWS_TO_RENDER_OVER_VIEWPORT } from './Table.constants';
import { TableProvider } from './Table.context';
import {
  TableBase,
  TableHeader,
  TableRow as RegularTableRow,
  TableBody as SimpleTableBody,
} from './Table.styled';

const getSortConfig = (sortData) => ({ sortBy: sortData.id, desc: sortData.desc });

export interface SimpleTableRef {
  scrollToRow: (index: Row<Data>['index'], position: ScrollIntoViewOptions['block']) => void;
  getRowElement: (index: Row<Data>['index']) => Element | undefined;
  toggleAllRowsSelected: (value?: boolean) => void;
  toggleRowSelected: (id: string, value?: boolean) => void;
  state: {
    sortConfig: Partial<SortingRule<Data>>;
    hasSelectedRows: boolean;
    hasAllRowsSelected: boolean;
  };
}

export interface SimpleTableProps {
  className?: string;
  columns: Column<Data>[];
  data: Data[];
  onSortChange?: (sortConfig: { sortBy: string; desc: boolean }) => void;
  onSelectionChange?: (selectedRows: string[]) => void;
  onValueChange?: (value: unknown) => void;
  defaultSortBy?: string;
  defaultDescOrder?: boolean;
  initialSortBy?: string;
  initialDescOrder?: boolean;
  selectedRows?: Record<string, boolean>;
  renderEmptyState?: () => React.ReactNode;
  emptyStateText?: string;
  emptyStateAction?: {
    text: string;
    onClick: () => void;
  };
  getRowProps?: (row: Row<Data>, index: number) => object;
  hiddenHeaders?: boolean;
  disabledWindowing?: boolean;
  RowComponent?: React.ReactElement;
  BodyComponent?: React.FC<TableBodyStyledProps>;
  readOnly?: boolean;
}

const SimpleTable = memo(
  forwardRef<SimpleTableRef, SimpleTableProps>((props, ref) => {
    const {
      className,
      columns = [],
      data = [],
      onSelectionChange,
      onSortChange,
      onValueChange,
      defaultSortBy = 'defaultSortBy',
      defaultDescOrder = false,
      initialSortBy,
      initialDescOrder,
      selectedRows = {},
      renderEmptyState: renderCustomEmptyState,
      emptyStateText = '',
      emptyStateAction,
      getRowProps,
      hiddenHeaders,
      disabledWindowing,
      RowComponent = null,
      BodyComponent = null,
      readOnly,
    } = props;

    const defaultSort = useMemo<SortingRule<Data>[]>(
      () => [{ id: defaultSortBy, desc: defaultDescOrder }],
      [defaultSortBy, defaultDescOrder],
    );
    const initialSort = useMemo<SortingRule<Data>[]>(
      () => [{ id: initialSortBy ?? defaultSortBy, desc: initialDescOrder ?? defaultDescOrder }],
      [initialSortBy, defaultSortBy, initialDescOrder, defaultDescOrder],
    );
    const [currentSorting, setCurrentSorting] = useState(defaultSort[0]);

    const isFirstMount = useFirstMountState();

    const [lastFocusedRowId, setLastFocusedRowId] = useState<number | null>(0);
    const contextValue = useMemo(
      () => ({ lastFocusedRowId, setLastFocusedRowId }),
      [lastFocusedRowId, setLastFocusedRowId],
    );

    /*
      Table init
    */
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      isAllRowsSelected,
      state,
      setSortBy,
      toggleAllRowsSelected,
      toggleRowSelected,
    } = useTable<Data>(
      {
        columns,
        data,
        manualSortBy: true,
        autoResetSortBy: false,
        autoResetSelectedRows: false,
        disableMultiSort: true,
        onValueChange,
        stateReducer: (newState, action) => {
          switch (action.type) {
            case 'toggleAllRowsSelected': {
              setLastFocusedRowId(0);
              return {
                ...newState,
                selectedRowIds: !action.value ? {} : newState.selectedRowIds,
              };
            }

            default:
              return newState;
          }
        },
        initialState: {
          sortBy: initialSort,
          selectedRowIds: selectedRows,
        },
      },
      useFlexLayout,
      useSortBy,
      useRowSelect,
    );

    /*
      Keyboard navigation
    */
    const bodyRef = useRef<HTMLTableElement>(null);
    const outerListRef = useRef<HTMLDivElement>(null);
    const innerListRef = useRef<HTMLDivElement>(null);
    const positionMapping = useRef({});
    const scrollOffset = useRef(0);
    const listHeight = useRef(0);
    const pageOffset = listHeight.current * 5;
    const maxHeight = Number(
      innerListRef.current?.style.height.replace('px', '') || listHeight.current,
    );

    useEffect(() => {
      const handleKeyDown = ({ keyCode }) => {
        if (positionMapping.current[keyCode] && outerListRef.current) {
          const getTopPosition = () => ({
            [KEY_CODES_BINDINGS.PAGE_UP]: Math.max(0, scrollOffset.current - pageOffset),
            [KEY_CODES_BINDINGS.PAGE_DOWN]: Math.min(scrollOffset.current + pageOffset, maxHeight),
            [KEY_CODES_BINDINGS.END]: maxHeight,
            [KEY_CODES_BINDINGS.HOME]: 0,
          });

          scrollOffset.current = getTopPosition()[keyCode];
          outerListRef.current.scrollTo({
            left: 0,
            top: scrollOffset.current,
            behavior: 'auto',
          });
        }
      };
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleResize = useCallback(({ height }) => {
      listHeight.current = height;
    }, []);

    /*
      Callbacks
    */
    useEffect(() => {
      if (isFirstMount) {
        return;
      }

      onSelectionChange?.(selectedFlatRows.map(path(['original', 'id'])) as string[]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSelectionChange, selectedFlatRows.length]);

    useEffect(() => {
      if (isFirstMount) {
        return;
      }

      if (state.sortBy.length) {
        onSortChange?.(getSortConfig(state.sortBy[0]));
        setCurrentSorting(state.sortBy[0]);
      } else {
        const nextSortBy =
          currentSorting.id === defaultSort[0].id
            ? [{ ...currentSorting, desc: !currentSorting.desc }]
            : defaultSort;

        setSortBy(nextSortBy);
        setCurrentSorting(nextSortBy[0]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSortChange, state.sortBy]);

    const listRef = useRef<FixedSizeList>(null);

    useImperativeHandle(
      ref,
      () => ({
        scrollToRow: (index, position) => {
          if (disabledWindowing) {
            // eslint-disable-next-line no-unused-expressions
            bodyRef.current
              ?.querySelectorAll('[role="row"]')
              [index]?.scrollIntoView({ behavior: 'smooth', block: position });
          } else {
            listRef.current?.scrollToItem?.(index);
          }
        },
        getRowElement: (index) => bodyRef.current?.querySelectorAll('[role="row"]')[index],
        toggleAllRowsSelected,
        toggleRowSelected,
        state: {
          sortConfig: getSortConfig(state.sortBy[0] || defaultSort),
          hasSelectedRows: !!selectedFlatRows.length,
          hasAllRowsSelected: isAllRowsSelected,
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        listRef,
        state.sortBy,
        selectedFlatRows.length,
        disabledWindowing,
        bodyRef,
        isAllRowsSelected,
      ],
    );

    /*
      Renderers
    */
    const renderDefaultEmptyState = renderWhenTrue(() => (
      <EmptyState isVisible text={emptyStateText} action={emptyStateAction} />
    ));

    const rowData = useMemo(
      () => ({
        items: rows,
        getRowProps,
        prepareRow,
        Component: RowComponent,
        readOnly,
      }),
      [RowComponent, getRowProps, prepareRow, readOnly, rows],
    );

    const renderVirtualizedList = () => (
      <AutoSizer onResize={handleResize}>
        {({ height, width }: Size) => (
          <FixedSizeList
            ref={listRef}
            outerRef={outerListRef}
            innerRef={innerListRef}
            itemCount={rows.length}
            itemData={rowData}
            itemSize={ROW_HEIGHT}
            overscanCount={ROWS_TO_RENDER_OVER_VIEWPORT}
            width={width as number}
            height={height as number}
          >
            {TableRow}
          </FixedSizeList>
        )}
      </AutoSizer>
    );

    const TableBody = BodyComponent || SimpleTableBody;

    return (
      <TableProvider value={contextValue}>
        <TableBase {...getTableProps()} ref={bodyRef} className={className} role="table">
          {!hiddenHeaders && (
            <TableHeader>
              {headerGroups.map((headerGroup) => (
                <RegularTableRow {...headerGroup.getHeaderGroupProps()} role="row">
                  {headerGroup.headers.map((column) => (
                    <ColumnHeader {...column.getHeaderProps()} column={column} />
                  ))}
                </RegularTableRow>
              ))}
            </TableHeader>
          )}
          <TableBody {...getTableBodyProps()} disabledWindowing={disabledWindowing} role="rowgroup">
            {disabledWindowing
              ? rows.map((row, index) => (
                  <TableRow
                    key={rowData.items[index].original.id || rowData.items[index].original.frontId}
                    data={rowData}
                    index={index}
                  />
                ))
              : renderVirtualizedList()}
            {renderCustomEmptyState
              ? renderCustomEmptyState()
              : renderDefaultEmptyState(!rows.length)}
          </TableBody>
        </TableBase>
      </TableProvider>
    );
  }),
);

SimpleTable.displayName = 'SimpleTable';

export default SimpleTable;
