import { TOOLTIP_ANCHOR } from '@bugbug/core/components/Tooltip';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Switch } from './SwitchCell.styled';

const SwitchCell = memo((props) => {
  const { t } = useTranslation();
  const { className, row, value, column, onValueChange, readOnly } = props;

  const handleValueChange = useCallback(
    (e) => {
      onValueChange(row.index, column.id, e.target.checked, row.original);
      return false;
    },
    [onValueChange, row, column],
  );

  const label = value ? t('switchCell.enabled', 'Enabled') : t('switchCell.disabled', 'Disabled');

  return (
    <Container className={className} data-testid="SwitchCell">
      <div>
        <Switch
          name="value"
          tooltip={row.original.switchDisabled ? column.disabledTooltip : undefined}
          tooltipAnchor={TOOLTIP_ANCHOR.BOTTOM_CENTER}
          data-testid="SwitchCell.Switch"
          value={value}
          onChange={handleValueChange}
          disabled={readOnly || row.original.switchDisabled}
          label={column.showLabel ? label : null}
        />
      </div>
    </Container>
  );
});

SwitchCell.displayName = 'SwitchCell';

SwitchCell.defaultProps = {
  className: null,
  readOnly: false,
  value: false,
  onValueChange: Function.prototype,
};

SwitchCell.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onValueChange: PropTypes.func,
  value: PropTypes.bool,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
    disabledTooltip: PropTypes.string,
  }).isRequired,
  row: PropTypes.shape({
    index: PropTypes.number,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      switchDisabled: PropTypes.bool,
    }),
  }).isRequired,
};

export default SwitchCell;
