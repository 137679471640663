import mixins from '@bugbug/core/theme/mixins';
import { TextBlock as TextBlockBase } from '@bugbug/core/theme/typography';
import styled, { css } from 'styled-components';

const textEllipsis = css`
  ${mixins.lineClamp({ lines: 2 })}
  white-space: inherit;
`;

export const TextBlock = styled(TextBlockBase)`
  ${textEllipsis}
`;

const BaseContainer = styled.div`
  ${textEllipsis};
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  div {
    margin-right: 5px;
    margin-bottom: 2px;
  }
`;

export const AnswerPromptParamsContainer = styled(BaseContainer)`
  ${TextBlock} {
    margin-left: 3px;
    -webkit-line-clamp: 1;
    width: fit-content;
    word-break: break-all;
  }
`;

export const SetLocalVariableParamsContainer = styled(AnswerPromptParamsContainer)``;

export const AssertParamsContainer = styled(BaseContainer)`
  flex-wrap: nowrap;

  & > span:first-child {
    margin-right: 3px;
    flex: none;
    padding-bottom: 2px;
  }

  ${TextBlock} {
    -webkit-line-clamp: 1;
    width: fit-content;
    word-break: break-all;
  }
`;

export const SwitchContextParamsContainer = styled(BaseContainer)`
  flex-wrap: nowrap;

  ${TextBlock} {
    -webkit-line-clamp: 1;
    flex: none;
    width: fit-content;
  }

  div {
    flex: none;
  }

  ${TextBlock} + div {
    margin-left: 5px;
  }

  > span:last-child {
    flex: 1;
  }
`;

export const SelectParamsContainer = styled(BaseContainer)`
  > span:first-child {
    margin-right: 5px;
  }
  ${TextBlock} {
    -webkit-line-clamp: 1;
    flex: none;
    width: fit-content;
  }
`;
