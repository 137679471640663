import CheckboxBase from '@bugbug/core/components/Checkbox';
import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  margin-left: -2px;

  &:hover {
    background-color: rgba(29, 12, 36, 0.04);
  }
`;

export const Checkbox = styled(CheckboxBase)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.TRANSPARENT};
`;
