import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme';
import mixins from '@bugbug/core/theme/mixins';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop } from 'ramda';
import styled, { css } from 'styled-components';

export const Container = styled.span`
  display: block;
  word-break: break-word;
  line-height: 15px;
  padding-bottom: 1px;

  ${mixins.textEllipsis}

  ${styleWhenTrue(
    prop('isUrl'),
    css`
      display: flex;
      align-items: center;
    `,
  )}
`;

export const ExternalLink = styled.a`
  margin-left: 5px;
`;

export const LinkIcon = styled(Icon).attrs(() => ({
  name: 'externalLink',
}))`
  width: 18px;
  color: ${COLOR.PRIMARY};
  transition: stroke 300ms ease;

  &:hover {
    stroke: ${COLOR.PRIMARY_LIGHT};
  }
`;
