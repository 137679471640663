import { UnescapedValue } from '@bugbug/core/theme/typography';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';

import { SwitchContextParamsContainer, TextBlock } from './ParamsCell.styled';

const SwitchContextParams = React.memo(({ className, tab = '', frame = '' }) => (
  <SwitchContextParamsContainer className={className}>
    <Trans i18nKey="switchContextParams.content">
      <div>tab:</div>
      <TextBlock>{{ tab }}</TextBlock>
      <div>frame:</div>
      <TextBlock>
        <UnescapedValue>{{ frame }}</UnescapedValue>
      </TextBlock>
    </Trans>
  </SwitchContextParamsContainer>
));

SwitchContextParams.displayName = 'SwitchContextParams';

SwitchContextParams.propTypes = {
  className: PropTypes.string,
  tab: PropTypes.number.isRequired,
  frame: PropTypes.string.isRequired,
};

export default SwitchContextParams;
