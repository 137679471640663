import PropTypes from 'prop-types';
import React from 'react';

import { Container, Check, Add } from './SelectionIconCell.styled';

const SelectionIconCell = ({ className, style, checked, onChange, disabled }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onChange({ target: { checked: !checked } });
  };

  return (
    <Container
      className={className}
      data-testid="SelectionIconCell"
      onClick={handleClick}
      disabled={disabled}
      style={style}
    >
      {checked ? (
        <Check data-testid="SelectionIconCell.CheckIcon" />
      ) : (
        <Add data-testid="SelectionIconCell.AddIcon" />
      )}
    </Container>
  );
};

SelectionIconCell.defaultProps = {
  className: null,
  checked: false,
  onChange: Function.prototype,
  disabled: false,
};

SelectionIconCell.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default SelectionIconCell;
