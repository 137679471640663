import PropTypes from 'prop-types';
import { equals, props } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import FilePreview from '~/components/FilePreview';
import { STEP_TYPE } from '~/constants/step';
import { selectStepParams } from '~/modules/test/test.selectors';
import useStepContext from '~/views/TestDetails/TestDetails.context';

import TextCell from '../TextCell';

import AnswerPromptParams from './AnswerPromptParams';
import AssertParams from './AssertParams';
import { TextBlock } from './ParamsCell.styled';
import SelectParams from './SelectParams';
import SwitchContextParams from './SwitchContextParams';

function areEqual(prevProps, nextProps) {
  const propsToCompare = ['id'];
  return equals(props(propsToCompare, prevProps), props(propsToCompare, nextProps));
}

const ParamsCell = React.memo(({ id, type }) => {
  const { runResultEnabled } = useStepContext();
  const paramValue = useSelector(selectStepParams(id, runResultEnabled));

  if (type === STEP_TYPE.UPLOAD_FILE) {
    return (
      <TextBlock>
        <FilePreview name={paramValue} hideDownload />
      </TextBlock>
    );
  }

  if ([STEP_TYPE.CHANGE, STEP_TYPE.TYPE, STEP_TYPE.SET_LOCAL_VARIABLE].includes(type)) {
    return <TextBlock>{paramValue}</TextBlock>;
  }

  if (type === STEP_TYPE.ASSERT) {
    return <AssertParams {...paramValue} />;
  }

  if (type === STEP_TYPE.SELECT) {
    return <SelectParams {...paramValue} />;
  }

  if (type === STEP_TYPE.SWITCH_CONTEXT) {
    return <SwitchContextParams {...paramValue} />;
  }

  if (type === STEP_TYPE.ANSWER_PROMPT) {
    return <AnswerPromptParams value={paramValue} />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TextCell {...props} value={paramValue} parsedUrls />;
}, areEqual);

ParamsCell.displayName = 'ParamsCell';

ParamsCell.defaultProps = {
  className: null,
  value: {},
};

ParamsCell.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string.isRequired,
};

export default ParamsCell;
