import PropTypes from 'prop-types';
import querystring from 'query-string';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import React from 'react';

import useQueryString from '~/hooks/useQueryString';

import { DEFAULT_PAGE, ITEM_TYPE } from './Paginate.constants';
import { Container, Link } from './Paginate.styled';

const Paginate = (props) => {
  const { current, className, total, pageSize, onChange, withQueryParams } = props;
  const query = useQueryString();

  const renderItem = (pageNumber, type) => {
    const page = pageNumber < DEFAULT_PAGE ? DEFAULT_PAGE : pageNumber;
    const newQueryParams = {
      ...query,
      page,
    };

    const queryString = querystring.stringify(newQueryParams);

    return (
      <Link to={`?${queryString}`} disabled>
        {type === ITEM_TYPE.PAGE ? page : ''}
      </Link>
    );
  };

  return (
    <Container
      className={className}
      data-testid="Paginate"
      defaultPage={DEFAULT_PAGE}
      current={current}
      total={total}
      pageSize={pageSize}
      onChange={!withQueryParams ? onChange : Function.prototype}
      locale={localeInfo}
      showQuickChanger
      itemRender={withQueryParams ? renderItem : undefined}
    />
  );
};

Paginate.defaultProps = {
  className: null,
  withQueryParams: false,
  onChange: Function.prototype,
};

Paginate.propTypes = {
  className: PropTypes.string,
  withQueryParams: PropTypes.bool,
  onChange: PropTypes.func,
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default Paginate;
