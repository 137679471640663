import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import { styleWhenTrue } from '@bugbug/core/utils/rendering';
import { prop, path } from 'ramda';
import styled, { css } from 'styled-components';

import { HeaderCell } from '../../Table.styled';

const isSelectionColumn = path(['theme', 'isSelectionColumn']);

export const ColumnHeaderContainer = styled(HeaderCell)`
  border-top: none;
  color: ${COLOR.GRAY_13};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  text-transform: uppercase;
  vertical-align: middle;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  ${styleWhenTrue(
    prop('canSort'),
    css`
      padding-right: 25px;
    `,
  )}

  ${styleWhenTrue(
    isSelectionColumn,
    css`
      width: 100%;
      height: 100%;
    `,
  )}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  ${styleWhenTrue(
    isSelectionColumn,
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
    `,
  )}
`;

export const SortIconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: -1px;

  ${styleWhenTrue(
    prop('inverted'),
    css`
      transform: scaleY(-1);
    `,
  )}
`;

export const SortIcon = styled(Icon).attrs(() => ({
  name: 'sort',
}))`
  && {
    width: 20px !important;
    height: 20px !important;
  }
`;
