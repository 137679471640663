import { renderWhenTrueOtherwise } from '@bugbug/core/utils/rendering';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useUnmount } from 'react-use';
import { ThemeProvider } from 'styled-components';

import { RowCell } from '../../Table.styled';

import { Row, LinkRow } from './DataRow.styled';

const DataRow = (props) => {
  const { 'aria-expanded': ariaExpanded } = props;
  const { className, row, onMouseEnter, onMouseLeave, onClick, cellProps, to, style } = props;

  useUnmount(() => {
    row.remove();
  });

  const handleMouseEnter = useCallback(
    (event) => {
      onMouseEnter(event, row.original);
    },
    [onMouseEnter, row],
  );

  const handleClick = useCallback(
    (event) => {
      if (onClick) {
        onClick(event, row.original);
      }
    },
    [onClick, row.original],
  );

  const MemoizedCells = useMemo(
    () =>
      row.cells.map((cell, index) => (
        <RowCell
          key={cell.id}
          minWidth={cell.column.minWidth}
          maxWidth={cell.column.maxWidth}
          width={cell.column.width}
          aria-colindex={index}
        >
          {cell.render('Cell', cellProps)}
        </RowCell>
      )),
    [row.cells, cellProps],
  );

  const renderContent = renderWhenTrueOtherwise(
    () => (
      <LinkRow
        className={className}
        style={style}
        data-testid="DataRow"
        data-rowid={row.id}
        data-selected={row.isSelected()}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onMouseLeave}
        to={to}
      >
        {MemoizedCells}
      </LinkRow>
    ),
    () => (
      <Row
        className={className}
        style={style}
        data-testid="DataRow"
        data-rowid={row.id}
        data-selected={row.isSelected()}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleClick}
        aria-expanded={ariaExpanded}
      >
        {MemoizedCells}
      </Row>
    ),
  );

  return (
    <ThemeProvider theme={{ highlighted: row.isHighlighted(), clickable: !!onClick }}>
      {renderContent(!!to)}
    </ThemeProvider>
  );
};

DataRow.displayName = 'DataRow';

DataRow.defaultProps = {
  className: null,
  row: {},
  style: {},
  cellProps: {},
  onMouseEnter: Function.prototype,
  onMouseLeave: Function.prototype,
  onClick: null,
  to: null,
  'aria-expanded': false,
};

DataRow.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })]),
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  'aria-expanded': PropTypes.bool,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({}).isRequired,
    isSelected: PropTypes.func.isRequired,
    isHighlighted: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    cells: PropTypes.arrayOf(
      PropTypes.shape({
        render: PropTypes.func.isRequired,
      }),
    ).isRequired,
  }),
  cellProps: PropTypes.shape({
    dragHandleProps: PropTypes.shape({}),
  }),
};

export default DataRow;
