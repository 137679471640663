import styled from 'styled-components';

import AvatarBase from '~/components/Avatar';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Avatar = styled(AvatarBase)`
  margin-right: 16px;
  min-width: 32px;
`;

export const Text = styled.span`
  position: relative;
  top: -2px;
  font-size: 13px;
  line-height: 15px;
  word-break: break-word;
`;
