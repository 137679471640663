import { memo, useEffect, useState } from 'react';

import type { TooltipAnchor } from '../Tooltip';

import { useTimezone } from '../../hooks/useTimezone';
import { getRelativeTime } from '../../utils/dates';
import * as T from '../../utils/toolbox';
import Tooltip from '../Tooltip';

import { Container } from './RelativeTime.styled';

interface RelativeTimeProps {
  className?: string;
  date: string;
  noTooltip?: boolean;
  tooltipPosition?: TooltipAnchor;
  tooltipTemplate?: (userFriendlyDate: string) => string;
}

const RelativeTime = memo<RelativeTimeProps>(
  ({
    className,
    date,
    noTooltip = false,
    tooltipPosition = 'bottom-center',
    tooltipTemplate = T.identity,
  }) => {
    const { toUserTZ } = useTimezone();
    const [relativeTime, setRelativeTime] = useState<string | null>(null);

    const tooltipContent = tooltipTemplate(toUserTZ(date).userFriendly);

    useEffect(() => {
      setRelativeTime(getRelativeTime(date));

      const interval = setInterval(() => {
        setRelativeTime(getRelativeTime(date));
      }, 5000);

      return () => clearInterval(interval);
    }, [date]);

    return (
      <Container className={className} data-testid="RelativeTime">
        {noTooltip ? (
          relativeTime
        ) : (
          <Tooltip content={tooltipContent} anchor={tooltipPosition}>
            {relativeTime}
          </Tooltip>
        )}
      </Container>
    );
  },
);

RelativeTime.displayName = 'RelativeTime';

export default RelativeTime;
