import TooltipBase, { TOOLTIP_ANCHOR } from '@bugbug/core/components/Tooltip';
import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${COLOR.GRAY_12};

  svg {
    margin-right: 8px;
    width: 20px;
  }
`;

export const Tooltip = styled(TooltipBase).attrs(() => ({
  anchor: TOOLTIP_ANCHOR.BOTTOM_CENTER,
}))``;
