import i18n from 'i18next';

import type { ExtendedRunStatus } from '@bugbug/core/types/base';

export const STATUS_TOOLTIP: Record<Extract<ExtendedRunStatus, 'failed-retried'>, string> = {
  'failed-retried': i18n.t(
    'statusBadge.retriedOnFailure.tooltip',
    'Test failed and automatically retried. See suite settings.',
  ),
} as const;
