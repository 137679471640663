import Icon from '@bugbug/core/components/Icon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  SCREEN_RESOLUTION_ICON_NAME,
  SCREEN_RESOLUTION_LABEL,
  SCREEN_RESOLUTION_TYPE,
} from '~/constants/test';
import { selectProjectScreenSizeByType } from '~/modules/project/project.selectors';

import { Container, Tooltip } from './ScreenSizeCell.styled';

const ScreenSizeCell = ({ value }) => {
  const { t } = useTranslation();
  let {
    screenSizeType = SCREEN_RESOLUTION_TYPE.DESKTOP,
    browserWidth,
    browserHeight,
  } = value ?? {};
  const defaultScreenSize = useSelector(selectProjectScreenSizeByType(screenSizeType));

  if (!browserHeight && !browserWidth) {
    browserWidth = defaultScreenSize.width;
    browserHeight = defaultScreenSize.height;
  }

  const iconName = SCREEN_RESOLUTION_ICON_NAME[screenSizeType];
  return (
    <Tooltip
      content={t('screenSizeCell.tooltip', '{{browserWidth}}x{{browserHeight}}', {
        browserWidth,
        browserHeight,
      })}
    >
      <Container>
        <Icon name={iconName} />
        {SCREEN_RESOLUTION_LABEL[screenSizeType]}
      </Container>
    </Tooltip>
  );
};

export default ScreenSizeCell;
