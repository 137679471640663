import PropTypes from 'prop-types';
import React, {
  useCallback,
  memo,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { EditableText } from './EditableCell.styled';

const EditableCell = memo(
  forwardRef((props, ref) => {
    const { className, row, cell, column, onValueChange, readOnly, error } = props;
    const fieldRef = useRef();
    const handleValueChange = useCallback(
      (value) => {
        onValueChange(row.index, column.id, value, row.original);
      },
      [onValueChange, row, column],
    );

    useEffect(() => {
      if (error) {
        fieldRef.current.setError(error);
      }
    }, [error]);

    useImperativeHandle(ref, () => fieldRef.current, []);

    return (
      <EditableText
        ref={fieldRef}
        type="text"
        name="value"
        value={cell.value}
        className={className}
        onChange={handleValueChange}
        readOnly={readOnly}
      />
    );
  }),
);

EditableCell.displayName = 'EditableCell';

EditableCell.defaultProps = {
  className: null,
  readOnly: false,
  error: null,
};

EditableCell.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  column: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  cell: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
  row: PropTypes.shape({
    index: PropTypes.number,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default EditableCell;
