import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import styled from 'styled-components';

export const Container = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${COLOR.GRAY_25};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const Text = styled.span`
  position: relative;
  top: -1px;
  color: ${COLOR.WHITE};
  font-size: 14px;
  line-height: 14px;
  font-weight: ${FONT_WEIGHT.BOLD};
`;
