import Icon from '@bugbug/core/components/Icon';
import { COLOR } from '@bugbug/core/theme/colors';
import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  border: 0;
  box-shadow: none;
  background-color: ${COLOR.TRANSPARENT};

  &:not(:disabled):hover {
    background-color: rgba(29, 12, 36, 0.04);
  }

  &:focus {
    outline: 0;
  }
`;

export const Add = styled(Icon).attrs(() => ({
  name: 'add',
}))`
  color: ${COLOR.PRIMARY_LIGHT};
  font-size: 16px !important;
`;

export const Check = styled(Icon).attrs(() => ({
  name: 'check',
}))`
  color: ${COLOR.GRAY_13};
  width: 18px;
`;
